(function($) {
    $(document).ready(function() {
        // SPEED BUMP connected to a class added.
        var $speedbmp = $('.speed-bump');
        var $spdbmp;

       $speedbmp.click(function(e){
            //do something here
            $spdbmp = confirm("You are now leaving the Diversified Members Credit Union (DMCU) web site for one operated by a third party..\n\nDMCU has no responsibility for an external web site. It neither endorses the information, content, presentation. or accuracy nor make any warranty, express or implied, regarding any external site.\n\nThank you for visiting Diversified Members Credit Union."); 
            if($spdbmp != true) {
                e.preventDefault();
            }
        });
    });

})(jQuery);